// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import permissions from '@src/views/apps/roles-permissions/store'
import mainCategory from '../views/apps/master/topic/store'
import technology from '../views/apps/master/technology/store'
import category from '../views/apps/master/category/store'
import roles from '../views/apps/roles-permissions/roles/rolesStore'
import subTopic from '../views/apps/master/subtopic/store'
import incompleteProfile from '../views/apps/master/incompleteProfile/store'
import session from '../views/apps/Sessions/store'
import uploadPrivacyToc from '../views/apps/master/uploadPrivacyToc/store'
import assignTopic from '../views/apps/task&topics/assigntopic/store'
import assignTask from '../views/apps/task&topics/assigntask/store'
import userMentor from '../views/user-settings/usermentor/store'
import userTechnology from '../views/user-settings/usertechnology/store'
import user from '../views/apps/master/user/store'
import projectMaster from '../views/apps/Project-Mapping/projectMaster/store'
import userProject from '../views/user-settings/user-project/store'
import userRole from '../views/user-settings/userrole/store'
import dashboard from '../views/dashboard/store'
import bulkupload from '../views/pages/bulk-upload/store'
import project from '../views/apps/master/project/store'
import facilities from '../views/apps/master/facilities/store'
import locations from '../views/apps/master/locations/store'
import packagePlan from '../views/apps/master/package/store'
import transactions from '../views/apps/MCBReports/store'
import notification from '../views/apps/master/pushNotification/store'
import externalLinks from '../views/apps/master/externalLinks/store'
const rootReducer = {
  auth,
  navbar,
  layout,
  permissions,
  roles,
  uploadPrivacyToc,
  incompleteProfile,
  mainCategory,
  technology,
  category,
  subTopic,
  assignTopic,
  assignTask,
  userMentor,
  userTechnology,
  user,
  session,
  dashboard,
  projectMaster,
  userProject,
  userRole,
  bulkupload,
  project,
  facilities,
  locations,
  packagePlan,
  transactions,
  notification,
  externalLinks
}

export default rootReducer
